import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";
// import HomeView from "../views/HomeView.vue";
import HomeView from "../views/home/homeIndex.vue";
import FlowView from "../views/flow/flowIndex.vue";
import ReportView from "../views/report/reportIndex.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/FlowView",
    name: "flowView",
    component: FlowView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/ReportView",
    name: "reportView",
    component: ReportView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 在路由守卫中改变全局变量
router.afterEach((to, from, next) => {
  const store = useStore(); // 获取Vuex store实例
  // 根据路由变化设置全局变量
  console.log(window.location,'====')
  store.commit('SET_NAVBAR', window.location.pathname !== '/reportView');
  // next(); // 必须调用next()来resolve这个钩子
});

export default router;
