<template>
  <el-tabs v-model="state.activeName" class="flowTabs" @tab-click="handleClick">
    <el-tab-pane label="全部" name=""></el-tab-pane>
    <el-tab-pane label="账号出售" name="账号出售"></el-tab-pane>
    <el-tab-pane label="运营订单" name="运营订单"></el-tab-pane>
    <el-tab-pane label="收号放款" name="收号放款"></el-tab-pane>
    <el-tab-pane label="账号问题" name="账号问题"></el-tab-pane>
    <el-tab-pane label="收款单" name="收款单"></el-tab-pane>
    <el-tab-pane label="付款单" name="付款单"></el-tab-pane>
    <el-tab-pane label="其他收入" name="其他收入"></el-tab-pane>
    <el-tab-pane label="费用支出" name="费用支出"></el-tab-pane>
  </el-tabs>
  <el-table :data="state.tableData" border class="flowTable" maxHeight="80vh">
    <!-- <el-table-column fixed prop="flowMenu" label="流程类型" /> -->
    <el-table-column fixed prop="flowMenu" label="流程类型">
      <template #default="scope">
        <span
          @click="state.flowMenu = scope.row.flowMenu; state.formUuid = scope.row.formId; state.instanceId = scope.row.instanceId; state.dialogFormVisible = true;">{{
    scope.row.flowMenu }}</span>
      </template>
    </el-table-column>
    <!-- <el-table-column prop="formId" label="表单ID" /> -->
    <!-- <el-table-column prop="instanceId" label="流程实例" width="150" show-overflow-tooltip>
      <template #default="scope">
        <span
          @click="state.flowMenu = scope.row.flowMenu; state.formUuid = scope.row.formId; state.instanceId = scope.row.instanceId; state.dialogFormVisible = true;">查看</span>
      </template>
    </el-table-column> -->
    <!-- <el-table-column prop="salesman" label="业务员" /> -->
    <!-- <el-table-column prop="xxxx" label="单据编号" /> -->
    <!-- <el-table-column prop="xxxx" label="单据日期" /> -->
    <!-- <el-table-column prop="clientArray" label="客户/供应商" /> -->
    <el-table-column prop="clientTxt" label="客户/供应商" />
    <el-table-column prop="sumAmount" label="总金额" />
    <!-- <el-table-column prop="disAmount" label="折扣额" /> -->
    <el-table-column prop="creationTime" label="创建时间" width="180" />
    <el-table-column prop="modTime" label="修改时间" width="180" />
    <!-- <el-table-column prop="remark" label="备注" width="150" show-overflow-tooltip /> -->
    <el-table-column prop="instanceState" label="状态">
      <template #default="scope">
        <el-tag :type="scope.row.instanceState == '成功' ? `success` : scope.row.instanceState == '失败' ? `danger` : `warning`">{{ scope.row.instanceState }}</el-tag>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <el-pagination v-model:current-page="state.currentPage" v-model:page-size="state.pageSize"
      layout="prev, pager, next, jumper, ->, total" background :total="state.total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
  </div>

  <!-- 表单 -->
  <popupForm :dialogFormVisible="state.dialogFormVisible" :flowMenu="state.flowMenu" :formUuid="state.formUuid"
    :instanceId="state.instanceId" @toggleDialog="closeDialog" />

</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import type { TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { addFlow, delFlow, flowList } from '../../api/index';

const state: {
  activeName: string,//选项卡current
  tableData: any,//表格数据
  dialogFormVisible: boolean,//弹窗显隐
  flowMenu: string,//流程名称
  formUuid: string,//表单ID
  instanceId: string,//实例ID
  currentPage: number,//当前页码
  pageSize: number,//每页条数
  total: number,//总条数
} = reactive({
  activeName: "",
  tableData: [],
  dialogFormVisible: false,
  flowMenu: "",
  formUuid: "",
  instanceId: "",
  currentPage: 1,
  pageSize: 15,
  total: 100,
})

onMounted(() => {
  listDatas();
})

// // 删除数据
// const delDatas = async () => {
//   let jsons = {
//     "flowMenu": "收款单",
//     "formId": "FORM-5F9B8BFBA8394BD9A36810C08AF39D2ERVOB",
//     "instanceId": "e1c6ee94-72df-4b3d-9f45-f9f4090a8675",
//     // "salesman": "[\"01550866190035208739\"]",
//     "salesman": "",
//     "clientArray": "",
//     // "clientArray": "[{\"formType\":\"receipt\",\"formUuid\":\"FORM-CDF6F8A4E4B7479A8A4B2E8A59652CB2AXYR\",\"instanceId\":\"FINST-F1666YC1CBXN72X96CHLV4PHR3GG2RIGLSH0M33W1\",\"subTitle\":\"\",\"appType\":\"APP_FGP686C5HJJJUZTBVYQC\",\"pid\":\"1IC66671HZWOMAQBEIFJW875DKKI2IT47NI1MJ4\",\"title\":\"交易游\"}]",
//     "clientTxt": "A测试",
//     "account": "",
//     "sumAmount": "100",
//     "disAmount": "",
//     "remark": "",
//     "businessType": "应收款",
//     "subFormList": []
//   };
//   await delFlow(jsons)
//     .then((res: any) => {
//       console.log('删除数据-成功', res)//成功
//     })
//     .catch((error: any) => {
//       console.log('删除数据-失败', error)//失败
//     })
// };

// 新增列表
// const addDatas = async () => {
//   let jsons = {
//     "flowMenu": "账号出售",
//     "formId": "FORM-A5C91BB171E34187A132C377343A94187MAW",
//     "instanceId": "0a8df843-6ca9-4614-8b03-c92ad3aaa8ab",
//     "salesman": "[\"01550866190035208739\"]",
//     "clientArray": "[{\"formType\":\"receipt\",\"formUuid\":\"FORM-CDF6F8A4E4B7479A8A4B2E8A59652CB2AXYR\",\"instanceId\":\"FINST-F1666YC1CBXN72X96CHLV4PHR3GG2RIGLSH0M33W1\",\"subTitle\":\"\",\"appType\":\"APP_FGP686C5HJJJUZTBVYQC\",\"pid\":\"1IC66671HZWOMAQBEIFJW875DKKI2IT47NI1MJ4\",\"title\":\"交易游\"}]",
//     "clientTxt": "私域转账",
//     "account": "",
//     "sumAmount": "620",
//     "disAmount": "",
//     "remark": ""
//   };
//   await addFlow(jsons)
//     .then((res: any) => {
//       console.log('新增列表-成功', res)//成功
//     })
//     .catch((error: any) => {
//       console.log('新增列表-失败', error)//失败
//     })
// };

// 获取列表
const listDatas = async () => {
  let jsons = {
    flowMenu: state.activeName,
    // flowMenu: '账号出售',//流程名称
    // formId: 'FORM-A5C91BB171E34187A132C377343A94187MAW',//表单ID
    // instanceId: '',//实例ID
    // salesman: '',//业务员
    // clientArray: '',//客户/供应商数组
    // clientTxt: '',//客户/供应商文本
    // account: '',//收付款账号
    // sumAmount: 10,//总金额
    // disAmount: 2,//折扣额
    // remark: '',//备注
    page: state.currentPage,
    pageSize: state.pageSize
  };
  await flowList(jsons)
    .then((res: any) => {
      console.log('获取列表-成功', res)//成功
      state.total = res.result.count || 0;
      state.tableData = res.result.data;
    })
    .catch((error: any) => {
      console.log('获取列表-失败', error)//失败
    })
};

// const { proxy } = getCurrentInstance()

// 选项卡切换
const handleClick = (tab: TabsPaneContext, event: Event) => {
  console.log('选项卡切换', tab.props.label)
  state.activeName = tab.props.label === '全部' ? '' : tab.props.label;
  listDatas();
}

const handleSizeChange = (val: number) => {
  state.pageSize = val;
  listDatas();
}
const handleCurrentChange = (val: number) => {
  state.currentPage = val;
  listDatas();
}

// 关闭弹窗
const closeDialog = () => {
  state.flowMenu = '';
  state.formUuid = '';
  state.instanceId = '';
  state.dialogFormVisible = false;
}
</script>

<style lang="less" scoped>
.flowTabs {
  margin-top: 10px;

  .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  ::v-deep .el-tabs__item.is-active,
  ::v-deep .el-tabs__item:hover {
    color: #2e4099 !important;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #2e4099 !important;
  }
}

.flowTable {
  width: 100%;
  max-width: 100%;

  ::v-deep th.el-table__cell,
  .el-table-fixed-column--left {
    color: #fff;
    height: 46px !important;
    line-height: 46px !important;
    background-color: #2e4099 !important;
  }

  ::v-deep .cell {
    text-align: center;
  }

  ::v-deep .el-table__row .el-table__cell:nth-child(1) {
    color: #2e4099;
    cursor: pointer;
  }
}

.pagination {
  ::v-deep .el-pagination {
    display: inline-flex;
    margin: 50px auto 0;

    &.is-background .el-pager li.is-active {
      background-color: #2e4099 !important;

      &:hover {
        color: #fff !important;
      }
    }

    .el-pager li:hover {
      color: #2e4099 !important;
    }
  }
}
</style>
