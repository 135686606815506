import { AxiosRequestConfig } from 'axios';
import { request } from '../utils/request';

// export const articleCate = (params: any) => {
//     return request.get('', { params })
// }

// 新增流程实例
export const addFlow = (data: any) => {
    return request.post('/api/webservice/addProcessManagement', data )
}

// 流程实例列表
export const flowList = (data: any) => {
    return request.post('/api/webservice/getProcessManagementPage', data )
}

// 流程实例详情
export const flowInfo = (params: any) => {
    return request.get('/api/webservice/getInstanceByInstanceIdAndFormId', { params })
}

// 删除流程实例
export const delFlow = (data: any) => {
    return request.post('/api/webservice/addProcessManagementByCancel', data )
}

// export const XXXXXXXXX = (data: any) => {
//     return request({ url: ', method: 'post', params: data })
// }
