<template>
  <div>
    <div v-if="state.loading" style="position: fixed; top: 60px; width: 100vw; height: 100vh; z-index: 9999"
      v-loading="state.loading" element-loading-text="加载中..." :element-loading-svg="state.svg"
      element-loading-custom-class="custom-loading-svg" element-loading-svg-view-box="-10, -10, 50, 50"></div>
    <el-scrollbar height="98vh">
      <!-- <el-button @click="reports" style="">reports</el-button> -->
      <!-- position: fixed; left: 20px; bottom: 20px; -->
      <!--  type="border-card"  -->

      <el-tabs v-model="state.activeName" class="flowTabs" @tab-click="handleClick">
        <el-tab-pane label="现金流量表" name="report1">
        </el-tab-pane>
        <el-tab-pane label="利润表" name="report2">
        </el-tab-pane>
        <el-tab-pane label="资产负债表" name="report3">
        </el-tab-pane>
      </el-tabs>

      <reportIndex1 v-if="state.activeName === 'report1'" @isLoading="changeLoading" :activeName="state.activeName" :department="state.department"
        :gameName="state.gameName" :startAndEndTime="state.startAndEndTime" />
      <reportIndex2 v-if="state.activeName === 'report2'" @isLoading="changeLoading" :activeName="state.activeName" :department="state.department"
        :gameName="state.gameName" :startAndEndTime="state.startAndEndTime" />
      <reportIndex3 v-if="state.activeName === 'report3'" @isLoading="changeLoading" :activeName="state.activeName" :department="state.department"
        :gameName="state.gameName" :startAndEndTime="state.startAndEndTime" />

    </el-scrollbar>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import type { TabsPaneContext } from 'element-plus';
import reportIndex1 from './reportIndex1.vue';
import reportIndex2 from './reportIndex2.vue';
import reportIndex3 from './reportIndex3.vue';
import { addFlow, delFlow, flowList } from '../../api/index';

const state: {
  activeName: string,//选项卡current
  loading: boolean,//加载状态
  svg: string,
  department: string,//所在部门
  gameName: string,//游戏名称
  startAndEndTime: any,//查询时间
} = reactive({
  loading: ref(true),//加载状态
  activeName: "report1",
  svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,
  department: '',//所在部门
  gameName: '',//游戏名称
  startAndEndTime: [],//查询时间
})

onMounted(() => {
  // https://finace.nyyyds.com/reportView?department=843171643&gameName=1&startAndEndTime=1727884800000,1728662400000
  console.error(window.location.search, 'hh ')
  if (window.location.search) {
    // 传值了
    var params = window.location.search.split('?');
    if (params.length) {
      var paramsVal = params[1].split('&');
      console.log(paramsVal, 'paramsVal==')
      if (paramsVal.length) {
        var department = '';
        var gameName = '';
        var startAndEndTime = '';
        paramsVal.forEach(item => {
          if (item.indexOf('department') !== -1) {
            department = item.split('=')[1];
          } else if (item.indexOf('gameName') !== -1) {
            gameName = decodeURI(item.split('=')[1]);
          } else if (item.indexOf('startAndEndTime') !== -1) {
            startAndEndTime = item.split('=')[1];
          }
        })
        state.department = department;
        state.gameName = gameName;
        state.startAndEndTime = startAndEndTime;
        console.log(state.department, '===department===', state.gameName, '===gameName===', state.startAndEndTime, '===startAndEndTime===')
      }
    }
  }
})

// const reports = () => {
//   var tableHTML = document.querySelector("#myTable").outerHTML;
//   var xlsContent = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
//   xmlns="http://www.w3.org/TR/REC-html40">
// <head>
//   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
//   <meta name="ProgId" content="Excel.Sheet" /> 
// </head>
// <body>${tableHTML}</body>
// </html>`;
//   var blob = new Blob([xlsContent], { type: "application/vnd.ms-excel" });
//   var link = document.createElement("a");
//   link.href = URL.createObjectURL(blob);
//   link.download = "现金流量表.xls";
//   link.innerHTML = "现金流量表xls"
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// }


// 选项卡切换
const handleClick = (tab: TabsPaneContext, event: Event) => {
  state.loading = true;
  state.activeName = tab.props.name;
  // listDatas();
}

const changeLoading = (val: boolean) => {
  state.loading = val;
}
</script>

<style lang="less" scoped>
.flowTabs {
  margin-top: 10px;
  position: relative;

  .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  ::v-deep .el-tabs__item.is-active,
  ::v-deep .el-tabs__item:hover {
    // color: #2e4099 !important;
    color: #0089ff !important;
  }

  ::v-deep .el-tabs__active-bar {
    // background-color: #2e4099 !important;
    background-color: #0089ff !important;
  }
}

.custom-loading-svg {
  border: 10px solid red;
}

::v-deep.el-loading-mask {
  bottom: 40vh !important;
}
</style>
