import axios from 'axios';
// import { useStore } from "vuex";
// import { useRouter, useRoute } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";
// const store = useStore();
// const router = useRouter();
import router from '@/router'

const request = axios.create({
    baseURL: '',
    timeout: 20000, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: { // 设置后端需要的传参类型
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
})

// 请求拦截
request.interceptors.request.use(
    config => { // 发送请求之前 do things
        console.log(config,'config===')
        // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
        // config.headers = {
        //     'Content-Type': 'application/json' //配置请求头
        // }
        // if (localStorage.getItem('token')) {
        //     // config.params = { 'token': token } //如果要求携带在参数中
        //     config.headers.token = localStorage.getItem('token'); //如果要求携带在请求头中
        // }
        return config
    },
    error => { // 请求错误 do things
        return Promise.reject(error)
    }
)

// 响应拦截 === 可以根据服务器返回的状态码做相应的数据
request.interceptors.response.use(
    response => { // 对响应数据 do things
        const res = response;
        if (res.data.code == 403) {
            ElMessage.warning("403");
            // localStorage.setItem('XXXXXX', '');
            // setTimeout(() => {
            //     router.push({ path: '/xxxx' })
            // }, 1000)
        } else if (res.data.code === 500) {
            ElMessage.error(res.data.desc || '500');
        }
        return res.data
    },
    error => { // 响应错误 do things
        return Promise.reject(error)
    }
)

export { request };
