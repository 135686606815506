import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "container",
  id: "zcfzbTable"
}

import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { progressProps, type TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { addFlow, delFlow, flowList } from '../../api/index';
import { it } from 'element-plus/es/locale';
import { watch } from 'vue';

interface Props {
  activeName?: string,//当前tabs
  department?: string,//所在部门
  gameName?: string,//游戏名称
  startAndEndTime?: any,//查询时间
}

export default /*@__PURE__*/_defineComponent({
  __name: 'reportIndex3',
  props: {
    activeName: { default: '' },
    department: { default: '' },
    gameName: { default: '' },
    startAndEndTime: { default: [] }
  },
  emits: ['isLoading'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const state: {
  zcfzb: any,//资产负债表数据
} = reactive({
  zcfzb: [],//资产负债表数据
})

const emits = __emit;
const getTables = () => {
  setTimeout(() => {

    console.log('接口之前获取---徕卡看', props.department, props.gameName, props.startAndEndTime)

    let datas = [
      { params1: '流动资产：', params2: '', params3: '', params4: '', params5: '流动负债：', params6: '', params7: '', params8: '' },
      { params1: '货币资金', params2: '1', params3: '', params4: '', params5: '短期借款', params6: '31', params7: '', params8: '' },
      { params1: '短期投资', params2: '2', params3: '', params4: '', params5: '应付票据', params6: '32', params7: '', params8: '' },
      { params1: '应收票据', params2: '3', params3: '', params4: '', params5: '应付账款', params6: '33', params7: '', params8: '' },
      { params1: '应收账款', params2: '4', params3: '', params4: '', params5: '预收款项', params6: '34', params7: '', params8: '' },
      { params1: '预付款项', params2: '5', params3: '', params4: '', params5: '应付职工薪酬', params6: '35', params7: '', params8: '' },
      { params1: '应收股利', params2: '6', params3: '', params4: '', params5: '应交税费', params6: '36', params7: '', params8: '' },
      { params1: '应收利息', params2: '7', params3: '', params4: '', params5: '应付利息', params6: '37', params7: '', params8: '' },
      { params1: '其他应收款', params2: '8', params3: '', params4: '', params5: '应付利润', params6: '38', params7: '', params8: '' },
      { params1: '存货', params2: '9', params3: '', params4: '', params5: '其他应付款', params6: '39', params7: '', params8: '' },
      { params1: '其中：原材料', params2: '10', params3: '', params4: '', params5: '', params6: '', params7: '', params8: '' },
      { params1: '在产品', params2: '11', params3: '', params4: '', params5: '', params6: '', params7: '', params8: '' },
      { params1: '库存商品', params2: '12', params3: '', params4: '', params5: '', params6: '', params7: '', params8: '' },
      { params1: '周转材料', params2: '13', params3: '', params4: '', params5: '', params6: '', params7: '', params8: '' },
      { params1: '其他流动资产', params2: '14', params3: '', params4: '', params5: '其他流动负债', params6: '40', params7: '', params8: '' },
      { params1: '流动资产合计', params2: '15', params3: '', params4: '', params5: '流动负债合计', params6: '41', params7: '', params8: '' },
      { params1: '非流动资产：', params2: '', params3: '', params4: '', params5: '  非流动负债：', params6: '', params7: '', params8: '' },
      { params1: '长期债券投资', params2: '16', params3: '', params4: '', params5: '长期借款', params6: '42', params7: '', params8: '' },
      { params1: '长期股权投资', params2: '17', params3: '', params4: '', params5: '长期应付款', params6: '43', params7: '', params8: '' },
      { params1: '固定资产原价', params2: '18', params3: '', params4: '', params5: '递延收益', params6: '44', params7: '', params8: '' },
      { params1: '减：累计折旧', params2: '19', params3: '', params4: '', params5: '其他非流动负债', params6: '45', params7: '', params8: '' },
      { params1: '固定资产账面价值', params2: '20', params3: '', params4: '', params5: '非流动负债合计', params6: '46', params7: '', params8: '' },
      { params1: '在建工程', params2: '21', params3: '', params4: '', params5: '负债合计', params6: '47', params7: '', params8: '' },
      { params1: '工程物资', params2: '22', params3: '', params4: '', params5: '', params6: '', params7: '', params8: '' },
      { params1: '固定资产清理', params2: '23', params3: '', params4: '', params5: '', params6: '', params7: '', params8: '' },
      { params1: '生产性生物资产', params2: '24', params3: '', params4: '', params5: '所有者权益（或股东权益）：', params6: '', params7: '', params8: '' },
      { params1: '无形资产', params2: '25', params3: '', params4: '', params5: '实收资本（或股本）', params6: '48', params7: '', params8: '' },
      { params1: '开发支出', params2: '26', params3: '', params4: '', params5: '资本公积', params6: '49', params7: '', params8: '' },
      { params1: '长期待摊费用', params2: '27', params3: '', params4: '', params5: '盈余公积', params6: '50', params7: '', params8: '' },
      { params1: '其他非流动资产', params2: '28', params3: '', params4: '', params5: '未分配利润', params6: '51', params7: '', params8: '' },
      { params1: '非流动资产合计', params2: '29', params3: '', params4: '', params5: '所有者权益（或股东权益）合计', params6: '52', params7: '', params8: '' },
      { params1: '资产总计', params2: '30', params3: '', params4: '', params5: '负债和所有者权益（或股东权益）总计', params6: '53', params7: '', params8: '' },
    ];
    state.zcfzb = datas;
    // console.log(state.lrb, 'lrb===')
    emits('isLoading', false)
  }, 3000)
}

const base64 = (s) => {
  return window.btoa(unescape(encodeURIComponent(s)))
};
const reports = () => {
  var tabelStr = document.querySelector('#zcfzbTable').outerHTML;
  var uri = 'data:application/vnd.ms-excel;base64,';
  // 真正要导出（下载）的HTML模板
  var exportTemplate = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
      <x:Name>${tabelStr}</x:Name>
              <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
       </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
</head>
<body>
${tabelStr}
</body>
</html>`;
  var blob = new Blob([exportTemplate], { type: "application/vnd.ms-excel; charset=utf-8" });
  var link = document.createElement("a");
  link.href = uri + base64(exportTemplate);
  link.download = "资产负债表.xls";
  link.innerHTML = "资产负债表xls"
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  //   var tableHTML = document.querySelector("#zcfzbTable").outerHTML;
  //   var xlsContent = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
  //   xmlns="http://www.w3.org/TR/REC-html40">
  // <head>
  //   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  //   <meta name="ProgId" content="Excel.Sheet" /> 
  // </head>
  // <body>${tableHTML}</body>
  // </html>`;
  //   var blob = new Blob([xlsContent], { type: "application/vnd.ms-excel; charset=utf-8" });
  //   var link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = "资产负债表.xls";
  //   link.innerHTML = "资产负债表xls"
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
}

onMounted(() => {
  console.error('资产负债表=============================')
  getTables();
})

// watch(() => props.department, (newVal) => {
//   state.department = newVal;
// })
// watch(() => props.gameName, (newVal) => {
//   state.gameName = newVal;
// })
// watch(() => props.startAndEndTime, (newVal) => {
//   state.startAndEndTime = newVal;
// })



return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", null, "资产负债表", -1)),
        _createVNode(_component_el_table, {
          data: state.zcfzb,
          border: "",
          class: "zcfzbTable",
          maxHeight: "80vh",
          style: {"width":"90vw","margin":"0 auto"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "params1",
              label: "资产"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params2",
              label: "行次"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params3",
              label: "期末余额（汇总）"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params4",
              label: "年初余额（汇总）"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params5",
              label: "负债和所有者权益"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params6",
              label: "行次"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params7",
              label: "期末余额（汇总）"
            }),
            _createVNode(_component_el_table_column, {
              prop: "params8",
              label: "年初余额（汇总）"
            })
          ]),
          _: 1
        }, 8, ["data"])
      ])
    ]),
    _: 1
  }))
}
}

})