<template>
  <div class="home">
    <p class="tips">宜搭后台流程展示</p>
    <!-- <img alt="Vue logo" src="../../assets/imgs/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js + TypeScript App" /> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class HomeView extends Vue { }
</script>

<style lang="less" scoped>
.tips {
  text-align: left;
}
</style>
