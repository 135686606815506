<template>
  <el-scrollbar>
    <div class="container">
      <div class="conCenter" :style="{ width: state.maxWidth + `px` }">
        <h2>现金流量表</h2>
        <div class="dates dottedBotLine">
          <h3>日期：{{ state.currentDate.year }}年{{ state.currentDate.month }}月</h3>
        </div>
        <div class="headers1 dottedBotLine">
          <div class="dottedRightLine" style="width: 90px;">
            <h3>行次</h3>
          </div>
          <div class="thList1 dottedRightLine">
            <h3 class="dottedBotLine">项目组</h3>
            <h3>游戏名</h3>
          </div>
          <div class="dottedRightLine" style="width: 130px;">
            <h3>汇总金额</h3>
          </div>
          <div class="thList2" v-for="(item, index) in state.deptList" :key="index"
            :style="{ width: item.child.length > 0 ? item.child.length * 100 + `px` : `100px` }">
            <h3 class="dottedBotLine dottedRightLine"
              :style="{ height: item.child.length > 0 ? `40px` : `81px`, lineHeight: item.child.length > 0 ? `40px` : `27px`, borderBottom: item.child.length > 0 ? `1px dotted #70ad47` : `none` }">
              {{ item.label }}</h3>
            <div v-if="item.child.length > 0">
              <h3 class="dottedRightLine" v-for="(item2, index2) in item.child" :key="index2">{{ item2.label }}</h3>
            </div>
          </div>
        </div>
        <div class="mainTable">
          <div class="priceBegin dottedBotLine">
            <h3 class="dottedRightLine">一、期初货币资金</h3>
            <!-- <h3>{{ state.xjllb.initialFunds || 0.00 }}</h3> -->
            <h3>0.00</h3>
          </div>
          <div class="sectionCon dottedBotLine">
            <div class="sectionLeft">
              <h3 class="sectionTit dottedBotLine dottedRightLine">二、本期资金增加</h3>
              <div style="display: flex;">
                <div class="indexs">
                  <span class="dottedBotLine dottedRightLine">1</span>
                  <span class="dottedBotLine dottedRightLine">2</span>
                  <span class="dottedBotLine dottedRightLine">3</span>
                  <span class="dottedBotLine dottedRightLine">4</span>
                  <span class="dottedBotLine dottedRightLine">5</span>
                  <span class="dottedBotLine dottedRightLine">6</span>
                  <span class="dottedBotLine dottedRightLine">7</span>
                  <span class="dottedBotLine dottedRightLine">8</span>
                </div>
                <div class="flowMenus dottedRightLine">
                  <div class="flowMenusItem dottedBotLine">
                    <p class="dottedRightLine" style="height: 245px; line-height: 245px;">收款单</p>
                    <div>
                      <dl class="dottedBotLine">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">账号出售</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl class="dottedBotLine yellow">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">运营订单</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl>
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">其他收入</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div class="flowMenusItem dottedBotLine">
                    <p class="dottedRightLine">存取款单</p>
                    <dl>
                      <dt>
                        <p>备用金借款</p>
                      </dt>
                    </dl>
                  </div>
                  <div class="flowMenusItem dottedBotLine yellow">
                    <p style="width: 100%;">股东注资</p>
                  </div>
                </div>
              </div>
              <h3 class="sectionTit dottedBotLine dottedRightLine">三、本期资金减少</h3>
              <div style="display: flex;">
                <div class="indexs">
                  <span class="dottedBotLine dottedRightLine">1</span>
                  <span class="dottedBotLine dottedRightLine">2</span>
                  <span class="dottedBotLine dottedRightLine">3</span>
                  <span class="dottedBotLine dottedRightLine">4</span>
                  <span class="dottedBotLine dottedRightLine">5</span>
                  <span class="dottedBotLine dottedRightLine">6</span>
                  <span class="dottedBotLine dottedRightLine">7</span>
                  <span class="dottedBotLine dottedRightLine">8</span>
                  <span class="dottedBotLine dottedRightLine">9</span>
                  <span class="dottedBotLine dottedRightLine">10</span>
                  <span class="dottedBotLine dottedRightLine">11</span>
                  <span class="dottedBotLine dottedRightLine">12</span>
                  <span class="dottedBotLine dottedRightLine">13</span>
                  <span class="dottedBotLine dottedRightLine">14</span>
                  <span class="dottedBotLine dottedRightLine">15</span>
                </div>
                <div class="flowMenus dottedRightLine">
                  <div class="flowMenusItem dottedBotLine">
                    <p class="dottedRightLine" style="height: 491px; line-height: 491px;">付款单</p>
                    <div>
                      <dl class="dottedBotLine">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">收号放款(回收价+包赔费)</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl class="dottedBotLine">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">费用支出-售后支出</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl class="dottedBotLine">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">费用支出-充值话费</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl class="dottedBotLine">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">费用支出-运营佣金</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl class="dottedBotLine">
                        <dt class="dottedRightLine">
                          <p style="height: 81px; line-height: 81px;">费用支出-其他支出</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                      <dl class="yellow">
                        <dt class="dottedRightLine">
                          <p style="height: 80px; line-height: 80px;">运营订单</p>
                        </dt>
                        <dd>
                          <p class="dottedBotLine">销货单</p>
                          <p>退货单</p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div class="flowMenusItem dottedBotLine">
                    <p style="width: 100%;">职工薪酬</p>
                  </div>
                  <div class="flowMenusItem dottedBotLine">
                    <p class="dottedRightLine">存取款单</p>
                    <dl>
                      <dt>
                        <p>备用金借款归还</p>
                      </dt>
                    </dl>
                  </div>
                  <div class="flowMenusItem dottedBotLine yellow">
                    <p style="width: 100%;">股东分红</p>
                  </div>
                </div>
              </div>
              <h3 class="sectionTit dottedBotLine dottedRightLine">四、本期资金增减净值</h3>
              <div class="zjjz" style="line-height: 40px;">
                <h3 class="green dottedBotLine dottedRightLine" style="font-size: 15px;">上期末资金增减净值</h3>
                <p class="dottedBotLine dottedRightLine">减免</p>
                <p class="dottedRightLine">本期累计资金增减净值</p>
              </div>
            </div>
            <div class="sectionRight">
              <ul>
                <li class="dottedRightLine" v-for="(item, index) in state.xjllb.cashFlowStatementSonList" :key="index">
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <p>0.00</p>
                  <!-- <p>{{ item.currentIncrease || 0 }}</p>
                <p>{{ item.zhcsBillOfSales || 0 }}</p>
                <p>{{ item.zhcsGoodsReturnedNote || 0 }}</p>
                <p>{{ item.yyddBillOfSales || 0 }}</p>
                <p>{{ item.yyddGoodsReturnedNote || 0 }}</p>
                <p>{{ item.qtsrBillOfSales || 0 }}</p>
                <p>{{ item.qtsrGoodsReturnedNote || 0 }}</p>
                <p>{{ item.byjBorrowMoney || 0 }}</p>
                <p>{{ item.gdCapitalInjection || 0 }}</p>
                <p>{{ item.currentFundsReduce || 0 }}</p>
                <p>{{ item.shfkbillOfSales || 0 }}</p>
                <p>{{ item.shfkGoodsReturnedNote || 0 }}</p>
                <p>{{ item.shzfBillOfSales || 0 }}</p>
                <p>{{ item.shzfGoodsReturnedNote || 0 }}</p>
                <p>{{ item.czhfBillOfSales || 0 }}</p>
                <p>{{ item.czhfGoodsReturnedNote || 0 }}</p>
                <p>{{ item.yyyjBillOfSales || 0 }}</p>
                <p>{{ item.yyyjGoodsReturnedNote || 0 }}</p>
                <p>{{ item.qtzfBillOfSales || 0 }}</p>
                <p>{{ item.qtzfGoodsReturnedNote || 0 }}</p>
                <p>{{ item.yyddBillOfSalesByFk || 0 }}</p>
                <p>{{ item.yyddGoodsReturnedNoteByFk || 0 }}</p>
                <p>{{ item.payroll || 0 }}</p>
                <p>{{ item.pettyCashRepayment || 0 }}</p>
                <p>{{ item.gdshareOutBonus || 0 }}</p>
                <p>{{ item.CurrentFundsNetWorth || 0 }}</p>
                <p>{{ item.lsatEndingFundsNetWorth || 0 }}</p>
                <p>{{ item.derate || 0 }}</p>
                <p>{{ item.atPresentAccumulatedFundsNetWorth || 0 }}</p> -->
                </li>
              </ul>
            </div>
          </div>
          <div class="company">
            <h3 class="dottedBotLine sectionTit">团队</h3>
            <ul>
              <li>
                <p class="dottedBotLine dottedRightLine">累计未分配现金净值</p>
                <!-- <p class="dottedBotLine">{{ state.xjllb.undistributedFundsSum || 0 }}</p> -->
                <p class="dottedBotLine">0.00</p>
              </li>
              <li>
                <p class="dottedBotLine dottedRightLine">总经理分红现金净值</p>
                <!-- <p class="dottedBotLine">{{ state.xjllb.zjlShareOutBonus || 0 }}</p> -->
                <p class="dottedBotLine">0.00</p>
              </li>
              <li>
                <p class="dottedBotLine dottedRightLine">股东分红净值</p>
                <!-- <p class="dottedBotLine">{{ state.xjllb.gdShareOutBonus || 0.00 }}</p> -->
                <p class="dottedBotLine">0.00</p>
              </li>
              <li>
                <p class="dottedBotLine dottedRightLine">剩余未分配净值</p>
                <!-- <p class="dottedBotLine">{{ state.xjllb.residueUndistributed || 0 }}</p> -->
                <p class="dottedBotLine">0.00</p>
              </li>
            </ul>
          </div>
          <div class="priceEnd dottedBotLine">
            <h3 class="dottedRightLine">五、期末货币资金</h3>
            <!-- <h3>{{ state.xjllb.EndingFunds || 0.00 }}</h3> -->
            <h3>0.00</h3>
          </div>
          <div class="priceOK sectionTit">
            <h3 class="dottedRightLine">试算平衡</h3>
            <!-- <h3 style="color: #d13600;">{{ state.xjllb.trialBalancing || 0 }}</h3> -->
            <h3 style="color: #d13600;">OK</h3>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { progressProps, type TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { addFlow, delFlow, flowList } from '../../api/index';
import { it } from 'element-plus/es/locale';
import { watch } from 'vue';

interface Props {
  activeName?: string,//当前tabs
  department?: string,//所在部门
  gameName?: string,//游戏名称
  startAndEndTime?: any,//查询时间
}
const props = withDefaults(defineProps<Props>(), {
  activeName: '',//当前tabs
  department: '',//所在部门
  gameName: '',//游戏名称
  startAndEndTime: [],//查询时间
})

const state: {
  maxWidth: number,//最宽度
  currentDate: { year: number, month: number },//当前年月
  xjllb: any,//现金流量表数据
  deptList: any,//部门列表
} = reactive({
  maxWidth: 0,//最宽度
  currentDate: { year: "", month: "", },
  xjllb: {},//现金流量表数据
  deptList: [],//部门列表
})

const emits = defineEmits(['isLoading']);
const getTables = () => {
  setTimeout(() => {

    console.log('接口之前获取---徕卡看', props.department, props.gameName, props.startAndEndTime)

    let datas = {
      "trialBalancing": "试算平衡",
      "undistributedFundsSum": "累计未分配现金净值",
      "cashFlowStatementSonList": [
        // 部门游戏的那些数据集合 第一条数据是前面的汇总
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏1",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "A组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏2",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "A组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },

        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏3",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "A组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏4",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "B组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏5",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "B组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "团队",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        }
      ],
      "gdShareOutBonus": "股东分红净值",
      "zjlShareOutBonus": "总经理分红现金净值",
      "EndingFunds": "期末货币资金（拿取资金台账数据）",
      "initialFunds": "期初货币资金",
      "residueUndistributed": "剩余未分配净值"
    }
    if (datas && datas.cashFlowStatementSonList.length) {
      let arrs = JSON.parse(JSON.stringify(datas.cashFlowStatementSonList));
      const temp: any = {};
      for (let i in arrs) {
        let key = arrs[i].department; // 判断依据，利用对象的属性不能重复进行判断。
        if (key) {
          if (temp[key]) {
            temp[key].label = arrs[i].department;
            temp[key].child.push({ label: arrs[i].gameName });// 游戏名称
          } else {
            temp[key] = {};
            temp[key].label = arrs[i].department;
            temp[key].child = [];
            if (arrs[i].gameName) {
              temp[key].child.push({ label: arrs[i].gameName });// 游戏名称
            }
          }
        }
      }
      const newArry = [];
      for (let k in temp) {
        newArry.push(temp[k]);
      };
      // console.log(newArry, 'newArry===')
      state.deptList = newArry;
    }
    state.xjllb = datas;
    // console.log(state.xjllb, 'xjllb===')
    emits('isLoading', false)
  }, 3000)
  setTimeout(() => {
    if (props.activeName === 'report1') {
      var refElements = document.getElementsByClassName('headers1');
      state.maxWidth = refElements[0].offsetWidth;
      console.log(state.maxWidth, 'state.maxWidth==')
    }
  }, 3000)
}



const base642 = (s) => {
  return window.btoa(unescape(encodeURIComponent(s)))
};
const reports2 = () => {
  var tabelStr = document.querySelector('#xjllbTable').outerHTML;
  var uri = 'data:application/vnd.ms-excel;base64,';
  // 真正要导出（下载）的HTML模板
  var exportTemplate = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
      <x:Name>${tabelStr}</x:Name>
              <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
       </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
</head>
<body>
${tabelStr}
</body>
</html>`;
  var blob = new Blob([exportTemplate], { type: "application/vnd.ms-excel; charset=utf-8" });
  var link = document.createElement("a");
  link.href = uri + base642(exportTemplate);
  link.download = "现金流量表.xls";
  link.innerHTML = "现金流量表xls"
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  //   var tableHTML = document.querySelector("#zcfzbTable").outerHTML;
  //   var xlsContent = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
  //   xmlns="http://www.w3.org/TR/REC-html40">
  // <head>
  //   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  //   <meta name="ProgId" content="Excel.Sheet" /> 
  // </head>
  // <body>${tableHTML}</body>
  // </html>`;
  //   var blob = new Blob([xlsContent], { type: "application/vnd.ms-excel; charset=utf-8" });
  //   var link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = "资产负债表.xls";
  //   link.innerHTML = "资产负债表xls"
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
}

onMounted(() => {
  console.error('现金流量表=============================')
  const date = new Date();
  state.currentDate = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };

  getTables();
})

// watch(() => props.department, (newVal) => {
//   state.department = newVal;
// })
// watch(() => props.gameName, (newVal) => {
//   state.gameName = newVal;
// })
// watch(() => props.startAndEndTime, (newVal) => {
//   state.startAndEndTime = newVal;
// })


</script>

<style lang="less" scoped>
.container {}

.conCenter {
  // min-width: 1240px;
  min-width: 1120px;
  margin: 0 auto;
  font-size: 15px;
  color: #8f4f23;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #70ad47;
  background-color: #fff;
}

.green {
  color: #70ad47;
}

.yellow {
  background-color: #ffff00;
}

p {
  margin: 0;
  padding: 0;
}

h2 {
  width: 100%;
  height: 60px;
  color: #fff;
  line-height: 60px;
  background-color: #70ad47;
}

.dottedBotLine {
  border-bottom: 1px dotted #70ad47;
  box-sizing: border-box;
}

.dottedRightLine {
  border-right: 1px dotted #70ad47;
  box-sizing: border-box;
}

.dottedLeftLine {
  border-left: 1px dotted #70ad47;
  box-sizing: border-box;
}

.dates {
  width: 100%;
  height: 30px;
  text-align: left;
  line-height: 30px;
  text-indent: 5px;
}

.headers1 {
  height: 81px;
  background-color: #a8d08d;
  display: flex;

  div {
    height: 81px;
    line-height: 81px;
  }

  .thList1 {
    width: 420px;
    height: 81px;
    line-height: 40px;
  }

  .thList2 {
    div {
      display: flex;

      h3 {
        width: 100px;
        height: 40px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
        white-space: pre-wrap;
        overflow: hidden;
      }
    }
  }
}

.mainTable {
  width: 100%;
}

.priceBegin,
.priceEnd,
.priceOK {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;

  h3 {
    &:nth-child(1) {
      width: 510px;
    }

    &:nth-child(2) {
      flex: 1;
    }
  }
}

.sectionCon {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sectionLeft {
  width: 510px;
  line-height: 40px;
}

.sectionTit {
  background-color: #e2efda;
}

.indexs {
  float: left;
  width: 90px;
  display: flex;
  flex-direction: column;

  span {
    display: block;
    width: 90px;
    line-height: 40px;
  }
}

.flowMenus {
  width: 420px;

  .flowMenusItem {
    width: 100%;
    display: flex;

    &>p:nth-child(1) {
      width: 100px;
    }

    dl {
      width: 318px;
      display: flex;
      align-items: center;

      dt {
        flex: 1;
      }

      dd {
        width: 120px;
      }
    }
  }
}

.company {
  h3 {
    line-height: 40px;
  }

  ul {
    li {
      display: flex;

      p {
        line-height: 40px;

        &:nth-child(1) {
          width: 510px;
        }

        &:nth-child(2) {
          flex: 1;
        }
      }
    }
  }
}

.sectionRight {
  flex: 1;

  ul {
    li {
      float: left;
      width: 100px;

      &:nth-child(1) {
        width: 130px !important;
      }

      &:last-child {
        border: none;
      }

      p {
        height: 40px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
        white-space: pre-wrap;
        border-bottom: 1px dotted #70ad47;
        overflow: hidden;
        cursor: pointer;

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>