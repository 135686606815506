import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "headers2 dottedBotLine" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mainTable" }
const _hoisted_5 = { class: "sectionCon" }
const _hoisted_6 = { class: "sectionRight" }

import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { progressProps, type TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { addFlow, delFlow, flowList } from '../../api/index';
import { it } from 'element-plus/es/locale';
import { watch } from 'vue';

interface Props {
  activeName?: string,//当前tabs
  department?: string,//所在部门
  gameName?: string,//游戏名称
  startAndEndTime?: any,//查询时间
}

export default /*@__PURE__*/_defineComponent({
  __name: 'reportIndex2',
  props: {
    activeName: { default: '' },
    department: { default: '' },
    gameName: { default: '' },
    startAndEndTime: { default: [] }
  },
  emits: ['isLoading'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const state: {
  maxWidth: number,//最宽度
  lrb: any,//利润表数据
  deptList: any,//部门列表
} = reactive({
  maxWidth: 0,//最宽度
  lrb: {},//利润表数据
  deptList: [],//部门列表
})

const emits = __emit;
const getTables = () => {
  setTimeout(() => {

    console.log('接口之前获取---徕卡看', props.department, props.gameName, props.startAndEndTime)

    let datas = {
      "trialBalancing": "试算平衡",
      "undistributedFundsSum": "累计未分配现金净值",
      "cashFlowStatementSonList": [
        // 部门游戏的那些数据集合 第一条数据是前面的汇总
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏1",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "A组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏2",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "A组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },

        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏3",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "A组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏4",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "B组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "游戏5",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "B组",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        },
        {
          "zhcsBillOfSales": "账号出售 -销货单",
          "qtzfBillOfSales": "费用支出-其他支出--销货单",
          "qtzfGoodsReturnedNote": "费用支出-其他支出--退货单",
          "yyddBillOfSales": "运营订单 -销货单",
          "pettyCashRepayment": "备用金借款归还",
          "CurrentFundsNetWorth": "本期资金增减净值",
          "derate": "减免",
          "currentFundsReduce": "本期资金减少",
          "yyddBillOfSalesByFk": "运营订单--销货单",
          "gameName": "",
          "atPresentAccumulatedFundsNetWorth": "本期累计资金增减净值",
          "shfkbillOfSales": "收号放款(回收价+包赔费)--销货单",
          "currentIncrease": "本期资金增加",
          "department": "团队",
          "shzfBillOfSales": "费用支出-售后支出--销货单",
          "czhfGoodsReturnedNote": "费用支出-充值话费--退货单",
          "byjBorrowMoney": "备用金借款",
          "yyddGoodsReturnedNote": "运营订单 -退货单",
          "czhfBillOfSales": "费用支出-充值话费--销货单",
          "zhcsGoodsReturnedNote": "账号出售 -退货单",
          "gdCapitalInjection": "股东注资",
          "yyyjBillOfSales": "费用支出-运营佣金--销货单",
          "lsatEndingFundsNetWorth": "上期末资金增减净值",
          "shfkGoodsReturnedNote": "收号放款(回收价+包赔费)--退货单",
          "yyddGoodsReturnedNoteByFk": "运营订单--退货单",
          "gdshareOutBonus": "股东分红",
          "payroll": "职工薪酬",
          "yyyjGoodsReturnedNote": "费用支出-运营佣金--退货单",
          "shzfGoodsReturnedNote": "费用支出-售后支出--退货单"
        }
      ],
      "gdShareOutBonus": "股东分红净值",
      "zjlShareOutBonus": "总经理分红现金净值",
      "EndingFunds": "期末货币资金（拿取资金台账数据）",
      "initialFunds": "期初货币资金",
      "residueUndistributed": "剩余未分配净值"
    }
    if (datas && datas.cashFlowStatementSonList.length) {
      let arrs = JSON.parse(JSON.stringify(datas.cashFlowStatementSonList));
      const temp: any = {};
      for (let i in arrs) {
        let key = arrs[i].department; // 判断依据，利用对象的属性不能重复进行判断。
        if (key) {
          if (temp[key]) {
            temp[key].label = arrs[i].department;
            temp[key].child.push({ label: arrs[i].gameName });// 游戏名称
          } else {
            temp[key] = {};
            temp[key].label = arrs[i].department;
            temp[key].child = [];
            if (arrs[i].gameName) {
              temp[key].child.push({ label: arrs[i].gameName });// 游戏名称
            }
          }
        }
      }
      const newArry = [];
      for (let k in temp) {
        newArry.push(temp[k]);
      };
      // console.log(newArry, 'newArry===')
      state.deptList = newArry;
    }
    state.lrb = datas;
    // console.log(state.lrb, 'lrb===')
    emits('isLoading', false)
  }, 3000)
  setTimeout(() => {
    if (props.activeName === 'report2') {
      var refElements = document.getElementsByClassName('headers2');
      console.log(refElements[0].offsetWidth, 'refElements===')
      state.maxWidth = refElements[0].offsetWidth;
      console.log(state.maxWidth, 'state.maxWidth==')
    }
  }, 3000)
}

onMounted(() => {
  console.error('利润表=============================')
  getTables();
})

// watch(() => props.department, (newVal) => {
//   state.department = newVal;
// })
// watch(() => props.gameName, (newVal) => {
//   state.gameName = newVal;
// })
// watch(() => props.startAndEndTime, (newVal) => {
//   state.startAndEndTime = newVal;
// })



return (_ctx: any,_cache: any) => {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "conCenter",
          style: _normalizeStyle({ width: state.maxWidth + `px` })
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("h2", null, "利润表", -1)),
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "dottedRightLine",
              style: {"width":"90px"}
            }, [
              _createElementVNode("h3", null, "行次")
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "thList1 dottedRightLine" }, [
              _createElementVNode("h3", { class: "dottedBotLine" }, "项目组"),
              _createElementVNode("h3", null, "游戏名")
            ], -1)),
            _cache[2] || (_cache[2] = _createElementVNode("div", {
              class: "dottedRightLine",
              style: {"width":"130px"}
            }, [
              _createElementVNode("h3", null, "汇总金额")
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.deptList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "thList2",
                key: index,
                style: _normalizeStyle({ width: item.child.length > 0 ? item.child.length * 100 + `px` : `100px` })
              }, [
                _createElementVNode("h3", {
                  class: "dottedBotLine dottedRightLine",
                  style: _normalizeStyle({ height: item.child.length > 0 ? `40px` : `81px`, lineHeight: item.child.length > 0 ? `40px` : `27px`, borderBottom: item.child.length > 0 ? `1px dotted #333` : `none` })
                }, _toDisplayString(item.label), 5),
                (item.child.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.child, (item2, index2) => {
                        return (_openBlock(), _createElementBlock("h3", {
                          class: "dottedRightLine",
                          key: index2
                        }, _toDisplayString(item2.label), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 4))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sectionLeft" }, [
                _createElementVNode("dl", { class: "sectionTit dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "indexs dottedRightLine" }, "1")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, "（一）营业收入")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "2"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "3")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "账号出售(出售价)"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "4"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "5")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "其他业务收入"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "sectionTit dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "indexs dottedRightLine" }, "1")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, "（二）营业成本")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "2"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "3")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "账号出售(回收价+包赔费)"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "sectionTit dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "indexs dottedRightLine" }, "1")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, "（三）其他费用")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "2"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "3")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "费用支出-售后支出"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "2"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "3")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "费用支出-充值话费"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "2"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "3")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "费用支出-运营佣金"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "dottedBotLine" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "dottedBotLine dottedRightLine" }, "2"),
                    _createElementVNode("div", { class: "dottedRightLine" }, "3")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", {
                      class: "flowMenus dottedRightLine",
                      style: {"height":"81px","line-height":"81px"}
                    }, "费用支出-其他支出"),
                    _createElementVNode("div", { class: "flowMenusItem" }, [
                      _createElementVNode("p", { class: "dottedBotLine dottedRightLine" }, "销货单"),
                      _createElementVNode("p", { class: "dottedRightLine" }, "退货单")
                    ])
                  ])
                ]),
                _createElementVNode("dl", {
                  class: "sectionTit dottedBotLine",
                  style: {"color":"#111","background-color":"#fff"}
                }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "indexs dottedRightLine" }, "10")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", { class: "dottedRightLine" }, "职工薪酬")
                    ])
                  ])
                ]),
                _createElementVNode("dl", {
                  class: "sectionTit dottedBotLine",
                  style: {"color":"#111","background-color":"#fff"}
                }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "indexs dottedRightLine" }, "11")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", { class: "dottedRightLine" }, "预付款项-房租")
                    ])
                  ])
                ]),
                _createElementVNode("dl", { class: "sectionTit" }, [
                  _createElementVNode("dt", { class: "indexs" }, [
                    _createElementVNode("div", { class: "indexs dottedRightLine" }, "1")
                  ]),
                  _createElementVNode("dd", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, "项目组利润")
                    ])
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.lrb.cashFlowStatementSonList, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "dottedRightLine",
                      key: index
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1),
                      _createElementVNode("p", null, "0.00", -1)
                    ])))
                  }), 128))
                ])
              ])
            ])
          ])
        ], 4)
      ])
    ]),
    _: 1
  }))
}
}

})