import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from 'axios';
import VueAxios from 'vue-axios';
import "./assets/css/global.less";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const app = createApp(App);
// app.config.globalProperties.$tools = tools;
// app.component('Headers', headers);
app.use(store).use(router).use(VueAxios, axios).use(ElementPlus, { locale: zhCn }).mount('#app');
