import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { reactive, ref, watch, inject, onMounted, } from 'vue'
import { flowInfo } from '../../api/index';
// import { useI18n } from 'vue-i18n'
// import type baTableClass from '/@/utils/baTable'
// import FormItem from '/@/components/formItem/index.vue'
// import type { FormInstance, ElTree, FormItemRule } from 'element-plus'
// import { uuid } from '/@/utils/random'
// import { buildValidatorData } from '/@/utils/validate'
// import { ElNotification } from 'element-plus'

interface Props {
    dialogFormVisible?: boolean,//弹窗显隐
    flowMenu?: string,//流程名称
    formUuid?: string,//表单ID
    instanceId?: string,//实例ID
}

export default /*@__PURE__*/_defineComponent({
  __name: 'popupForm',
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    flowMenu: { default: '' },
    formUuid: { default: '' },
    instanceId: { default: '' }
  },
  emits: ['toggleDialog'],
  setup(__props: any, { emit: __emit }) {

const props = __props
// const formRef = ref<FormInstance>()
// const baTable = inject('baTable') as baTableClass
const state: {
    dialogFormVisible: boolean,//弹窗显隐
    flowMenu: string,//流程名称
    formUuid: string,//表单ID
    instanceId: string,//实例ID
    relevanceInfo: any,
} = reactive({
    dialogFormVisible: false,//弹窗显隐
    flowMenu: "",//流程名称
    formUuid: "",//表单ID
    instanceId: "",//实例ID
    relevanceInfo: {},
})

const yidaActiveNames = ref(['1', '2', '3', '4', '5', '6', '7', '8'])
const handleyidaChange = (val: string[]) => { }

// 流程详情
const getFlowInfo = async () => {
    let jsons = {
        formId: state.formUuid,
        instanceId: state.instanceId,
    };
    await flowInfo(jsons)
        .then((res: any) => {
            console.log('获取详情-成功', res)//成功
            state.relevanceInfo = res.result;
        })
        .catch((error: any) => {
            console.log('获取详情-失败', error)//失败
        })
};

// 转换时间戳
const timestampToDate = (timestamp: string) => {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = date.getMonth() + 1 < 11 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 月份从0开始，需要加1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
}

const emits = __emit;
const toggledialogVisible = () => {
    emits('toggleDialog', false)
    state.dialogFormVisible = false;
    state.flowMenu = '';
    state.formUuid = '';
    state.instanceId = '';
}
watch(
    () => props.dialogFormVisible,
    (newVal) => {
        if (newVal) {
            state.dialogFormVisible = props.dialogFormVisible;
            state.flowMenu = props.flowMenu;
            state.formUuid = props.formUuid;
            state.instanceId = props.instanceId;
            getFlowInfo();
        }
    }
)

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "yidaDialog",
    modelValue: state.dialogFormVisible,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((state.dialogFormVisible) = $event)),
    onClose: toggledialogVisible,
    width: "60%"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(state.flowMenu), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_scrollbar, { "max-height": "65vh" }, {
        default: _withCtx(() => [
          (state.flowMenu === '账号出售')
            ? (_openBlock(), _createBlock(_component_el_form, {
                key: 0,
                ref: "relevanceRef",
                model: state.relevanceInfo
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_collapse, {
                    modelValue: yidaActiveNames.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((yidaActiveNames).value = $event)),
                    onChange: handleyidaChange,
                    class: "yida-collapse"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_collapse_item, {
                        title: "状态信息分组",
                        name: "1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "核销状态：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3uc), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "收款状态：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3u8), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        title: "基本信息分组",
                        name: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_ly58t1vq), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lyiegp55)), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "客户：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly8186xt), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lycl1p0g ?
        state.relevanceInfo.employeeField_lycl1p0g[0] : ''), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "备注：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyo4e3tf), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 24,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_table, {
                                        data: state.relevanceInfo.tableField_lycge4cj,
                                        border: "",
                                        style: {"width":"100%"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table_column, {
                                            prop: "departmentSelectField_lz9povl6",
                                            label: "所在部门",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "selectField_ltdygwvr",
                                            label: "游戏名称",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "textField_lyo4e3tk",
                                            label: "游戏编号",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "textField_lzc1wjoe",
                                            label: "游戏账号",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "dateField_ly8186y3",
                                            label: "回收日期",
                                            width: "120"
                                          }, {
                                            default: _withCtx((scope) => [
                                              _createElementVNode("p", null, _toDisplayString(timestampToDate(scope.row.dateField_ly8186y3)), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "numberField_ly8186y2",
                                            label: "回收价",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "numberField_lz2dkua6",
                                            label: "包赔费",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "numberField_ly8186xw",
                                            label: "金额",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "numberField_lyjp5xy4",
                                            label: "利润差",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "numberField_leog6999",
                                            label: "收卖间隔",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "numberField_ldwnnw0i",
                                            label: "买家手机号",
                                            width: "120"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            prop: "textField_lyiegp57",
                                            label: "备注",
                                            width: "120"
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["data"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "销售总额：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lycge4ck || 0.00), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_col, {
                                span: 12,
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, { label: "实销金额：" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyjqwoen || 0.00), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["model"]))
            : (state.flowMenu === '账号出售')
              ? (_openBlock(), _createBlock(_component_el_form, {
                  key: 1,
                  ref: "relevanceRef",
                  model: state.relevanceInfo
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_collapse, {
                      modelValue: yidaActiveNames.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((yidaActiveNames).value = $event)),
                      onChange: handleyidaChange,
                      class: "yida-collapse"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_collapse_item, {
                          title: "状态信息分组",
                          name: "1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "收款核销状态：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3uc), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "付款核销状态：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0dgsyjr), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "收款状态：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3u8), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "付款状态：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0dgsyjq), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_collapse_item, {
                          title: "基本信息分组",
                          name: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lype0baz), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lyicv3d3)), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "供应商：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_ly5ifarv), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lype0bbd ? state.relevanceInfo.employeeField_lype0bbd[0] : ''), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "备注：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lype0bbe), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 24,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_table, {
                                          data: state.relevanceInfo.tableField_lyz7zldp,
                                          border: "",
                                          style: {"width":"100%"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_table_column, {
                                              prop: "radioField_lyz7zlds",
                                              label: "是否匹配",
                                              width: "120"
                                            }),
                                            _createVNode(_component_el_table_column, {
                                              prop: "dateField_lyz7zldu",
                                              label: "订单创建时间",
                                              width: "120"
                                            }),
                                            _createVNode(_component_el_table_column, {
                                              prop: "selectField_lyz7zldw",
                                              label: "交易状态",
                                              width: "120"
                                            }),
                                            _createVNode(_component_el_table_column, {
                                              prop: "departmentSelectField_lzxh80xm",
                                              label: "所在部门",
                                              width: "120"
                                            }),
                                            _createVNode(_component_el_table_column, {
                                              prop: "selectField_lyz7zldy",
                                              label: "淘宝店铺",
                                              width: "120"
                                            }),
                                            _createVNode(_component_el_table_column, {
                                              prop: "textField_lyz7zle0",
                                              label: "订单编号",
                                              width: "120"
                                            }),
                                            _createVNode(_component_el_table_column, {
                                              prop: "numberField_lyz7zle2",
                                              label: "订单金额",
                                              width: "120"
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["data"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "销售总额：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lea3z5hp), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, {
                                  span: 12,
                                  xs: { span: 24 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "实销金额：" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lduf8xqe), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["model"]))
              : (state.flowMenu === '收号放款')
                ? (_openBlock(), _createBlock(_component_el_form, {
                    key: 2,
                    ref: "relevanceRef",
                    model: state.relevanceInfo
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_collapse, {
                        modelValue: yidaActiveNames.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((yidaActiveNames).value = $event)),
                        onChange: handleyidaChange,
                        class: "yida-collapse"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_collapse_item, {
                            title: "状态信息分组",
                            name: "1"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "核销状态：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3uc), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "付款状态：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3u8), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_collapse_item, {
                            title: "基本信息分组",
                            name: "2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_ly58t1vq), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lye9pp7n)), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "供应商：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_ly5ifarv), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lwx46ahu ? state.relevanceInfo.employeeField_lwx46ahu[0] : ''), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "备注：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textareaField_lzgc4emi), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 24,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table, {
                                            data: state.relevanceInfo.tableField_lwx46ai5,
                                            border: "",
                                            style: {"width":"100%"}
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_table_column, {
                                                prop: "departmentSelectField_ly6y8asw",
                                                label: "所在部门",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "selectField_ltdygwvr",
                                                label: "游戏名称",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "textField_ldwnnw04",
                                                label: "游戏编号",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "textField_lyy86lz4",
                                                label: "游戏账号",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "numberField_ldwnnw0a",
                                                label: "回收价",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "numberField_lzqqum6y",
                                                label: "包赔费",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "employeeField_lyy86lz5",
                                                label: "回收人",
                                                width: "120"
                                              }),
                                              _createVNode(_component_el_table_column, {
                                                prop: "textareaField_lzgc4emj",
                                                label: "备注",
                                                width: "120"
                                              })
                                            ]),
                                            _: 1
                                          }, 8, ["data"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "付款总额：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly5kfyiu), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "实销金额：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyjz2dte), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_collapse_item, {
                            title: "收款信息分组",
                            name: "3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "账户类型：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_lyphgqcl), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "收款户名：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyphgqcm), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "收款账号：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyphgqck), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "开户银行：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lztx0yde), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_collapse_item, {
                            title: "一键付款分组",
                            name: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "付款账号：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_lyo4e3tx), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "付款户名：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyy86lz6), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 12,
                                    xs: { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, { label: "付款金额：" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyo4e3tz), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["model"]))
                : (state.flowMenu === '账号问题')
                  ? (_openBlock(), _createBlock(_component_el_form, {
                      key: 3,
                      ref: "relevanceRef",
                      model: state.relevanceInfo
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_collapse, {
                          modelValue: yidaActiveNames.value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((yidaActiveNames).value = $event)),
                          onChange: handleyidaChange,
                          class: "yida-collapse"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_collapse_item, {
                              title: "状态信息分组",
                              name: "1"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "收款核销状态：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0jdu6sx), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "付款核销状态：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0nfpxzy), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "收款状态：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0jdu6sy), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "付款状态：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0nfpxzz), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_collapse_item, {
                              title: "基本信息分组",
                              name: "2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lypfij38)), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lypfij39), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "问题类型：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lzzj4t0u), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "回收日期：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.dateField_ldxahn2z), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "所在部门：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.departmentSelectField_llm43fsm), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "游戏名称：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_ldwopc1d), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "游戏编号：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ldwnnw04), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "游戏账号：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lzzj4t0v), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "回收价：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ldwnnw0a), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "号库状态：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo62228), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "放款状态：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo6222b), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "备注：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textareaField_lzzj4t0y), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_collapse_item, {
                              title: "售后结果分组",
                              name: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "售后结果（原号主或包赔平台）：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lzzj4t10), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "要回金额：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ldx05jzx), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "备注：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lzzj4t1c), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "售后结果（客户）：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lzzj4t11), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "补偿金额：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_le9q8ojw), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "备注：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lzzj4t1d), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_collapse_item, {
                              title: "核实信息分组",
                              name: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_col, {
                                      span: 12,
                                      xs: { span: 24 }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_form_item, { label: "核实意见：" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textareaField_m012khpg), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["model"]))
                  : (state.flowMenu === '收款单')
                    ? (_openBlock(), _createBlock(_component_el_form, {
                        key: 4,
                        ref: "relevanceRef",
                        model: state.relevanceInfo
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_collapse, {
                            modelValue: yidaActiveNames.value,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((yidaActiveNames).value = $event)),
                            onChange: handleyidaChange,
                            class: "yida-collapse"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_collapse_item, {
                                title: "状态信息分组",
                                name: "1"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "核销状态：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lz2xr881), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "收款状态：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lzgsnzke), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_collapse_item, {
                                title: "基本信息分组",
                                name: "2"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_ly58t1vq), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lyicv3d3)), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "往来单位：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_ly58t1w8), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "业务类型：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_ly58t1w9), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lyi1k7ao ? state.relevanceInfo.employeeField_lyi1k7ao[0] : ''), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "备注：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyo3v6tk), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 24,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_table, {
                                                data: state.relevanceInfo.tableField_llnetcvd,
                                                border: "",
                                                style: {"width":"100%"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "associationFormField_m0dwlkdg",
                                                    label: "对账单",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "dateField_lycsht5h",
                                                    label: "入账时间",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "selectField_m0g0utzk",
                                                    label: "收款账号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_m0g0utzm",
                                                    label: "收款户名",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lycsht5n",
                                                    label: "对方名称",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lycsht5m",
                                                    label: "对方账号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lycsht5j",
                                                    label: "订单号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_m0g20ghd",
                                                    label: "支付宝交易号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "numberField_lycsht5k",
                                                    label: "收款金额",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textareaField_m0g0utzq",
                                                    label: "备注",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_m0tdup4q",
                                                    label: "流水ID",
                                                    width: "120"
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["data"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "收款合计：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1wl), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "现金折扣：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyr1blyt), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "可核销金额：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y1), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_collapse_item, {
                                title: "核销明细分组",
                                name: "3"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "未核销金额：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y0), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "本次核销金额：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y1), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "核销差额：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y2), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "单据范围：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.multiSelectField_ly58t1xh), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 24,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "核销明细表" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_table, {
                                                data: state.relevanceInfo.tableField_lz9lk4bj,
                                                border: "",
                                                style: {"width":"100%"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "selectField_lz9lk4br",
                                                    label: "单据类型",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "dateField_lz9lk4bp",
                                                    label: "单据日期",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lz9lk4bq",
                                                    label: "单据编号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "departmentSelectField_lz9lk4bo",
                                                    label: "所在部门",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lzc5qzi7",
                                                    label: "收入名称",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lz9lk4c4",
                                                    label: "游戏名称",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lz9lk4c3",
                                                    label: "游戏编号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lzc5qzi5",
                                                    label: "游戏账号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "textField_lzdhjjrl",
                                                    label: "订单编号",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "radioField_m0gewfyw",
                                                    label: "收支类型",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "numberField_lz9lk4c1",
                                                    label: "金额",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "numberField_lz9lk4c0",
                                                    label: "可核销金额",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "numberField_lz9lk4bz",
                                                    label: "本次核销金额",
                                                    width: "120"
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    prop: "numberField_lz9lk4by",
                                                    label: "折扣分摊额",
                                                    width: "120"
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["data"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 12,
                                        xs: { span: 24 }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "是否自动核销：" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_lymakgcw), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["model"]))
                    : (state.flowMenu === '付款单')
                      ? (_openBlock(), _createBlock(_component_el_form, {
                          key: 5,
                          ref: "relevanceRef",
                          model: state.relevanceInfo
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_collapse, {
                              modelValue: yidaActiveNames.value,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((yidaActiveNames).value = $event)),
                              onChange: handleyidaChange,
                              class: "yida-collapse"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_collapse_item, {
                                  title: "状态信息分组",
                                  name: "1"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "核销状态：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lz2xr881), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "付款状态：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lzgssg0d), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_collapse_item, {
                                  title: "基本信息分组",
                                  name: "2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_ly58t1vq), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lyicv3d3)), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "往来单位：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_ly58t1w8), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "业务类型：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_ly58t1w9), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lyi1k7ao ? state.relevanceInfo.employeeField_lyi1k7ao[0] : ''), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "备注：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyo46d6k), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        (state.relevanceInfo.radioField_m0w0uvzz)
                                          ? (_openBlock(), _createBlock(_component_el_col, {
                                              key: 0,
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "选择对账单：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_m0w0uvzz), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (state.relevanceInfo.radioField_m0w0uvzz && state.relevanceInfo.radioField_m0w0uvzz == '是')
                                          ? (_openBlock(), _createBlock(_component_el_col, {
                                              key: 1,
                                              span: 24,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_el_table, {
                                                      data: state.relevanceInfo.tableField_llnetcvd,
                                                      border: "",
                                                      style: {"width":"100%"}
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "associationFormField_lysksosx",
                                                          label: "对账单",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "dateField_lycsht5h",
                                                          label: "入账时间",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "selectField_m0f1ecbj",
                                                          label: "付款账号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0f1ecbl",
                                                          label: "付款户名",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_lycsht5n",
                                                          label: "对方名称",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_lycsht5m",
                                                          label: "对方账号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_lycsht5j",
                                                          label: "订单号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0g23w3q",
                                                          label: "支付宝交易号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "numberField_lycsht5k",
                                                          label: "付款金额",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textareaField_lycsht5o",
                                                          label: "备注",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0te1jza",
                                                          label: "流水ID",
                                                          width: "120"
                                                        })
                                                      ]),
                                                      _: 1
                                                    }, 8, ["data"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (state.relevanceInfo.radioField_m0w0uvzz && state.relevanceInfo.radioField_m0w0uvzz == '否')
                                          ? (_openBlock(), _createBlock(_component_el_col, {
                                              key: 2,
                                              span: 24,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_el_table, {
                                                      data: state.relevanceInfo.tableField_m0w0uw0c,
                                                      border: "",
                                                      style: {"width":"100%"}
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "dateField_m0w0uw01",
                                                          label: "入账时间",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "selectField_m0w0uw02",
                                                          label: "付款账号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0w0uw03",
                                                          label: "付款户名",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0w0uw04",
                                                          label: "对方名称",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0w0uw05",
                                                          label: "对方账号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0w0uw06",
                                                          label: "订单号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_m0w0uw07",
                                                          label: "支付宝交易号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "numberField_m0w0uw08",
                                                          label: "付款金额",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textareaField_m0w0uw09",
                                                          label: "备注",
                                                          width: "120"
                                                        })
                                                      ]),
                                                      _: 1
                                                    }, 8, ["data"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "付款合计：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1wl), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "现金折扣：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyr1blyt), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "可核销金额：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y1), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_collapse_item, {
                                  title: "核销明细分组",
                                  name: "3"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "未核销金额：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y0), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "本次核销金额：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y1), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "核销差额：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly58t1y2), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "单据范围：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.multiSelectField_ly58t1xh), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 24,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_table, {
                                                  data: state.relevanceInfo.tableField_lz8a2w5i,
                                                  border: "",
                                                  style: {"width":"100%"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "selectField_lz8a2w5k",
                                                      label: "单据类型",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "dateField_lz8a2w5o",
                                                      label: "单据日期",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "textField_lz8a2w5m",
                                                      label: "单据编号",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "departmentSelectField_lz8a2w5q",
                                                      label: "所在部门",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "selectField_lz8a2w5s",
                                                      label: "游戏名称",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "textField_lz8a2w5u",
                                                      label: "游戏编号",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "textField_lzc60ft1",
                                                      label: "游戏账号",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "textField_lzc60ft3",
                                                      label: "费用名称",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "associationFormField_ldxhle1k",
                                                      label: "手机号码",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "textField_m0f5p48q",
                                                      label: "订单编号",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "radioField_m0gervf3",
                                                      label: "收支类型",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "numberField_lz8a2w5w",
                                                      label: "金额",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "numberField_lz8a2w5x",
                                                      label: "可核销金额",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "numberField_lz8a2w5y",
                                                      label: "本次核销金额",
                                                      width: "120"
                                                    }),
                                                    _createVNode(_component_el_table_column, {
                                                      prop: "numberField_lz8a2w5z",
                                                      label: "折扣分摊额",
                                                      width: "120"
                                                    })
                                                  ]),
                                                  _: 1
                                                }, 8, ["data"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "是否确认核销：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_y56tcfqh), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_col, {
                                          span: 12,
                                          xs: { span: 24 }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_form_item, { label: "是否自动核销：" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_lymakgcw), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["model"]))
                      : (state.flowMenu === '其他收入')
                        ? (_openBlock(), _createBlock(_component_el_form, {
                            key: 6,
                            ref: "relevanceRef",
                            model: state.relevanceInfo
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_collapse, {
                                modelValue: yidaActiveNames.value,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((yidaActiveNames).value = $event)),
                                onChange: handleyidaChange,
                                class: "yida-collapse"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_collapse_item, {
                                    title: "状态信息分组",
                                    name: "1"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "核销状态：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3uc), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "收款状态：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3u8), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_collapse_item, {
                                    title: "基本信息分组",
                                    name: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo2e1ui), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lyiegp55)), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "业务类型：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_m0w85n5z), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "往来单位：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_ly5ifarv), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lz7rz2v0 ? state.relevanceInfo.employeeField_lz7rz2v0[0] : ''), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "备注：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyo2e1us), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 24,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_table, {
                                                    data: state.relevanceInfo.tableField_ly6n4ibl,
                                                    border: "",
                                                    style: {"width":"100%"}
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_el_table_column, {
                                                        prop: "departmentSelectField_llm3pdr7",
                                                        label: "所在部门",
                                                        width: "120"
                                                      }),
                                                      _createVNode(_component_el_table_column, {
                                                        prop: "textField_lyo8sh9y",
                                                        label: "收入名称",
                                                        width: "120"
                                                      }),
                                                      _createVNode(_component_el_table_column, {
                                                        prop: "numberField_ly6n4ibo",
                                                        label: "金额",
                                                        width: "120"
                                                      }),
                                                      _createVNode(_component_el_table_column, {
                                                        prop: "textField_lymnh7fa",
                                                        label: "备注",
                                                        width: "120"
                                                      }),
                                                      _createVNode(_component_el_table_column, {
                                                        prop: "numberField_lzgtaap6",
                                                        label: "已核销金额",
                                                        width: "120"
                                                      })
                                                    ]),
                                                    _: 1
                                                  }, 8, ["data"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "收入总额：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ly5kfyiu), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "实销金额：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lys09x3t), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_collapse_item, {
                                    title: "一键收款分组",
                                    name: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "收款账号：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_lyo2e1ux), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "收款户名：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyy8retv), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "收款金额：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyo2e1v4), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_col, {
                                            span: 12,
                                            xs: { span: 24 }
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, { label: "支付宝交易号：" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_m1aqp2sl), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["model"]))
                        : (state.flowMenu === '费用支出')
                          ? (_openBlock(), _createBlock(_component_el_form, {
                              key: 7,
                              ref: "relevanceRef",
                              model: state.relevanceInfo
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_collapse, {
                                  modelValue: yidaActiveNames.value,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((yidaActiveNames).value = $event)),
                                  onChange: handleyidaChange,
                                  class: "yida-collapse"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_collapse_item, {
                                      title: "状态信息分组",
                                      name: "1"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "核销状态：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3uc), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "付款状态：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo4e3u8), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_collapse_item, {
                                      title: "基本信息分组",
                                      name: "2"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "单据类型：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo2e1ui), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "单据日期：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(timestampToDate(state.relevanceInfo.dateField_lyiegp55)), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "单据编号：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_ly73ykjc), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "支出类型：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lyo350nc), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "业务类型：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.selectField_m0w7ze16), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "往来单位：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_lyo350nl), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "业务员：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.employeeField_lz80glbk ? state.relevanceInfo.employeeField_lz80glbk[0] : ''), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "备注：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lzy4kqq8), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 24,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_el_table, {
                                                      data: state.relevanceInfo.tableField_ly6n4ibl,
                                                      border: "",
                                                      style: {"width":"100%"}
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "departmentSelectField_llm3pdr7",
                                                          label: "所在部门",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_lyo8sh9y",
                                                          label: "费用名称",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "associationFormField_ldxhle1k",
                                                          label: "手机号码",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "associationFormField_lfanejmi",
                                                          label: "游戏号库",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "selectField_lyo350nm",
                                                          label: "游戏名称",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_lzc24a4n",
                                                          label: "游戏编号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textField_lzc24a4p",
                                                          label: "游戏账号",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "numberField_ly6n4ibo",
                                                          label: "金额",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "numberField_lz80glbj",
                                                          label: "费用分摊",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "numberField_lzgolvp1",
                                                          label: "已核销金额",
                                                          width: "120"
                                                        }),
                                                        _createVNode(_component_el_table_column, {
                                                          prop: "textareaField_lzgs4pfv",
                                                          label: "备注",
                                                          width: "120"
                                                        })
                                                      ]),
                                                      _: 1
                                                    }, 8, ["data"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "付款总额：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_ldwudpgc), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "实销金额：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lys09x3t), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_collapse_item, {
                                      title: "一键付款分组",
                                      name: "3"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "付款账号：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.associationFormField_lyo2e1ux), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "付款户名：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_lyy8qcly), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "付款金额：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.numberField_lyo2e1v4), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "支付宝交易号：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.textField_m1arm2z0), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_el_col, {
                                              span: 12,
                                              xs: { span: 24 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_form_item, { label: "自动审核：" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("p", null, _toDisplayString(state.relevanceInfo.radioField_lzwggl5w), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }, 8, ["model"]))
                          : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})