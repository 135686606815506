import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "pagination" }

import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import type { TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { addFlow, delFlow, flowList } from '../../api/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'flowIndex',
  setup(__props) {

const state: {
  activeName: string,//选项卡current
  tableData: any,//表格数据
  dialogFormVisible: boolean,//弹窗显隐
  flowMenu: string,//流程名称
  formUuid: string,//表单ID
  instanceId: string,//实例ID
  currentPage: number,//当前页码
  pageSize: number,//每页条数
  total: number,//总条数
} = reactive({
  activeName: "",
  tableData: [],
  dialogFormVisible: false,
  flowMenu: "",
  formUuid: "",
  instanceId: "",
  currentPage: 1,
  pageSize: 15,
  total: 100,
})

onMounted(() => {
  listDatas();
})

// // 删除数据
// const delDatas = async () => {
//   let jsons = {
//     "flowMenu": "收款单",
//     "formId": "FORM-5F9B8BFBA8394BD9A36810C08AF39D2ERVOB",
//     "instanceId": "e1c6ee94-72df-4b3d-9f45-f9f4090a8675",
//     // "salesman": "[\"01550866190035208739\"]",
//     "salesman": "",
//     "clientArray": "",
//     // "clientArray": "[{\"formType\":\"receipt\",\"formUuid\":\"FORM-CDF6F8A4E4B7479A8A4B2E8A59652CB2AXYR\",\"instanceId\":\"FINST-F1666YC1CBXN72X96CHLV4PHR3GG2RIGLSH0M33W1\",\"subTitle\":\"\",\"appType\":\"APP_FGP686C5HJJJUZTBVYQC\",\"pid\":\"1IC66671HZWOMAQBEIFJW875DKKI2IT47NI1MJ4\",\"title\":\"交易游\"}]",
//     "clientTxt": "A测试",
//     "account": "",
//     "sumAmount": "100",
//     "disAmount": "",
//     "remark": "",
//     "businessType": "应收款",
//     "subFormList": []
//   };
//   await delFlow(jsons)
//     .then((res: any) => {
//       console.log('删除数据-成功', res)//成功
//     })
//     .catch((error: any) => {
//       console.log('删除数据-失败', error)//失败
//     })
// };

// 新增列表
// const addDatas = async () => {
//   let jsons = {
//     "flowMenu": "账号出售",
//     "formId": "FORM-A5C91BB171E34187A132C377343A94187MAW",
//     "instanceId": "0a8df843-6ca9-4614-8b03-c92ad3aaa8ab",
//     "salesman": "[\"01550866190035208739\"]",
//     "clientArray": "[{\"formType\":\"receipt\",\"formUuid\":\"FORM-CDF6F8A4E4B7479A8A4B2E8A59652CB2AXYR\",\"instanceId\":\"FINST-F1666YC1CBXN72X96CHLV4PHR3GG2RIGLSH0M33W1\",\"subTitle\":\"\",\"appType\":\"APP_FGP686C5HJJJUZTBVYQC\",\"pid\":\"1IC66671HZWOMAQBEIFJW875DKKI2IT47NI1MJ4\",\"title\":\"交易游\"}]",
//     "clientTxt": "私域转账",
//     "account": "",
//     "sumAmount": "620",
//     "disAmount": "",
//     "remark": ""
//   };
//   await addFlow(jsons)
//     .then((res: any) => {
//       console.log('新增列表-成功', res)//成功
//     })
//     .catch((error: any) => {
//       console.log('新增列表-失败', error)//失败
//     })
// };

// 获取列表
const listDatas = async () => {
  let jsons = {
    flowMenu: state.activeName,
    // flowMenu: '账号出售',//流程名称
    // formId: 'FORM-A5C91BB171E34187A132C377343A94187MAW',//表单ID
    // instanceId: '',//实例ID
    // salesman: '',//业务员
    // clientArray: '',//客户/供应商数组
    // clientTxt: '',//客户/供应商文本
    // account: '',//收付款账号
    // sumAmount: 10,//总金额
    // disAmount: 2,//折扣额
    // remark: '',//备注
    page: state.currentPage,
    pageSize: state.pageSize
  };
  await flowList(jsons)
    .then((res: any) => {
      console.log('获取列表-成功', res)//成功
      state.total = res.result.count || 0;
      state.tableData = res.result.data;
    })
    .catch((error: any) => {
      console.log('获取列表-失败', error)//失败
    })
};

// const { proxy } = getCurrentInstance()

// 选项卡切换
const handleClick = (tab: TabsPaneContext, event: Event) => {
  console.log('选项卡切换', tab.props.label)
  state.activeName = tab.props.label === '全部' ? '' : tab.props.label;
  listDatas();
}

const handleSizeChange = (val: number) => {
  state.pageSize = val;
  listDatas();
}
const handleCurrentChange = (val: number) => {
  state.currentPage = val;
  listDatas();
}

// 关闭弹窗
const closeDialog = () => {
  state.flowMenu = '';
  state.formUuid = '';
  state.instanceId = '';
  state.dialogFormVisible = false;
}

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      modelValue: state.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.activeName) = $event)),
      class: "flowTabs",
      onTabClick: handleClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "全部",
          name: ""
        }),
        _createVNode(_component_el_tab_pane, {
          label: "账号出售",
          name: "账号出售"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "运营订单",
          name: "运营订单"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "收号放款",
          name: "收号放款"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "账号问题",
          name: "账号问题"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "收款单",
          name: "收款单"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "付款单",
          name: "付款单"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "其他收入",
          name: "其他收入"
        }),
        _createVNode(_component_el_tab_pane, {
          label: "费用支出",
          name: "费用支出"
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_table, {
      data: state.tableData,
      border: "",
      class: "flowTable",
      maxHeight: "80vh"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          fixed: "",
          prop: "flowMenu",
          label: "流程类型"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              onClick: ($event: any) => {state.flowMenu = scope.row.flowMenu; state.formUuid = scope.row.formId; state.instanceId = scope.row.instanceId; state.dialogFormVisible = true;}
            }, _toDisplayString(scope.row.flowMenu), 9, _hoisted_1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "clientTxt",
          label: "客户/供应商"
        }),
        _createVNode(_component_el_table_column, {
          prop: "sumAmount",
          label: "总金额"
        }),
        _createVNode(_component_el_table_column, {
          prop: "creationTime",
          label: "创建时间",
          width: "180"
        }),
        _createVNode(_component_el_table_column, {
          prop: "modTime",
          label: "修改时间",
          width: "180"
        }),
        _createVNode(_component_el_table_column, {
          prop: "instanceState",
          label: "状态"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tag, {
              type: scope.row.instanceState == '成功' ? `success` : scope.row.instanceState == '失败' ? `danger` : `warning`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.instanceState), 1)
              ]),
              _: 2
            }, 1032, ["type"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_pagination, {
        "current-page": state.currentPage,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((state.currentPage) = $event)),
        "page-size": state.pageSize,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((state.pageSize) = $event)),
        layout: "prev, pager, next, jumper, ->, total",
        background: "",
        total: state.total,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])
    ]),
    _createVNode(popupForm, {
      dialogFormVisible: state.dialogFormVisible,
      flowMenu: state.flowMenu,
      formUuid: state.formUuid,
      instanceId: state.instanceId,
      onToggleDialog: closeDialog
    }, null, 8, ["dialogFormVisible", "flowMenu", "formUuid", "instanceId"])
  ], 64))
}
}

})