import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["element-loading-svg"]

import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import type { TabsPaneContext } from 'element-plus';
import reportIndex1 from './reportIndex1.vue';
import reportIndex2 from './reportIndex2.vue';
import reportIndex3 from './reportIndex3.vue';
import { addFlow, delFlow, flowList } from '../../api/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'reportIndex',
  setup(__props) {

const state: {
  activeName: string,//选项卡current
  loading: boolean,//加载状态
  svg: string,
  department: string,//所在部门
  gameName: string,//游戏名称
  startAndEndTime: any,//查询时间
} = reactive({
  loading: ref(true),//加载状态
  activeName: "report1",
  svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,
  department: '',//所在部门
  gameName: '',//游戏名称
  startAndEndTime: [],//查询时间
})

onMounted(() => {
  // https://finace.nyyyds.com/reportView?department=843171643&gameName=1&startAndEndTime=1727884800000,1728662400000
  console.error(window.location.search, 'hh ')
  if (window.location.search) {
    // 传值了
    var params = window.location.search.split('?');
    if (params.length) {
      var paramsVal = params[1].split('&');
      console.log(paramsVal, 'paramsVal==')
      if (paramsVal.length) {
        var department = '';
        var gameName = '';
        var startAndEndTime = '';
        paramsVal.forEach(item => {
          if (item.indexOf('department') !== -1) {
            department = item.split('=')[1];
          } else if (item.indexOf('gameName') !== -1) {
            gameName = decodeURI(item.split('=')[1]);
          } else if (item.indexOf('startAndEndTime') !== -1) {
            startAndEndTime = item.split('=')[1];
          }
        })
        state.department = department;
        state.gameName = gameName;
        state.startAndEndTime = startAndEndTime;
        console.log(state.department, '===department===', state.gameName, '===gameName===', state.startAndEndTime, '===startAndEndTime===')
      }
    }
  }
})

// const reports = () => {
//   var tableHTML = document.querySelector("#myTable").outerHTML;
//   var xlsContent = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
//   xmlns="http://www.w3.org/TR/REC-html40">
// <head>
//   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
//   <meta name="ProgId" content="Excel.Sheet" /> 
// </head>
// <body>${tableHTML}</body>
// </html>`;
//   var blob = new Blob([xlsContent], { type: "application/vnd.ms-excel" });
//   var link = document.createElement("a");
//   link.href = URL.createObjectURL(blob);
//   link.download = "现金流量表.xls";
//   link.innerHTML = "现金流量表xls"
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// }


// 选项卡切换
const handleClick = (tab: TabsPaneContext, event: Event) => {
  state.loading = true;
  state.activeName = tab.props.name;
  // listDatas();
}

const changeLoading = (val: boolean) => {
  state.loading = val;
}

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (state.loading)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          style: {"position":"fixed","top":"60px","width":"100vw","height":"100vh","z-index":"9999"},
          "element-loading-text": "加载中...",
          "element-loading-svg": state.svg,
          "element-loading-custom-class": "custom-loading-svg",
          "element-loading-svg-view-box": "-10, -10, 50, 50"
        }, null, 8, _hoisted_1)), [
          [_directive_loading, state.loading]
        ])
      : _createCommentVNode("", true),
    _createVNode(_component_el_scrollbar, { height: "98vh" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tabs, {
          modelValue: state.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.activeName) = $event)),
          class: "flowTabs",
          onTabClick: handleClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "现金流量表",
              name: "report1"
            }),
            _createVNode(_component_el_tab_pane, {
              label: "利润表",
              name: "report2"
            }),
            _createVNode(_component_el_tab_pane, {
              label: "资产负债表",
              name: "report3"
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        (state.activeName === 'report1')
          ? (_openBlock(), _createBlock(reportIndex1, {
              key: 0,
              onIsLoading: changeLoading,
              activeName: state.activeName,
              department: state.department,
              gameName: state.gameName,
              startAndEndTime: state.startAndEndTime
            }, null, 8, ["activeName", "department", "gameName", "startAndEndTime"]))
          : _createCommentVNode("", true),
        (state.activeName === 'report2')
          ? (_openBlock(), _createBlock(reportIndex2, {
              key: 1,
              onIsLoading: changeLoading,
              activeName: state.activeName,
              department: state.department,
              gameName: state.gameName,
              startAndEndTime: state.startAndEndTime
            }, null, 8, ["activeName", "department", "gameName", "startAndEndTime"]))
          : _createCommentVNode("", true),
        (state.activeName === 'report3')
          ? (_openBlock(), _createBlock(reportIndex3, {
              key: 2,
              onIsLoading: changeLoading,
              activeName: state.activeName,
              department: state.department,
              gameName: state.gameName,
              startAndEndTime: state.startAndEndTime
            }, null, 8, ["activeName", "department", "gameName", "startAndEndTime"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})